* {
  margin: 0;
  padding: 0;
  font-family: "Mulish", sans-serif;
}

h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 55px;
  color: #161617;
  margin-bottom: 25px;
}

h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 55px;
  color: #161617;
  margin-bottom: 25px;
}

p {
  color: #58585d;
  margin-bottom: 20px;
  font-size: 16px;
}

.container {
  padding: 25px;
  margin-top: 65px;
}

@media (min-width: 768px) {
  .container {
    padding: 0 144px;
  }
}

/* Search Bar */
.search-bar {
  max-width: 640px;
  background-color: #fdfdfd;
  border: #efefef solid 1px;
  border-radius: 4px;
  margin: 0 auto;
  padding-left: 55px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.search-bar button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 2px;
}

.search-bar input {
  height: 36px;
  width: 100%;
  padding-left: 10px;
  background-color: transparent;
  border: none;
  outline: none;
}

/* Icons */
.icons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
  gap: 25px;
}

/* Icon */
.icon {
  width: 200px;
  height: 175px;
  background-color: #fdfdfd;
  border: #efefef solid 1px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
  cursor: pointer;
  margin-bottom: 25px;
  /* margin-right: 25px; */
}

.icon:hover {
  background-color: #eeeeee;
}

.icon .icon-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.icon .icon-container img {
  width: 100px;
  height: 100px;
}
.icon .icon-container small {
  color: #58585d;
  margin-top: 10px;
  text-align: center;
}

/*  Modal */
.modal {
  min-height: 250px;
  width: 75%;
  max-width: 800px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fdfdfd;
  border: #efefef solid 1px;
  border-radius: 4px;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
  padding: 20px;
  display: block;
}

.modal-hide {
  display: none;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.modal-header .modal-close-button{
  background-color: transparent;
  border: #efefef solid 1px;
  padding: 5px 8px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}

.modal-content{
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
}



.modal-content .learn{
  margin-top: 15px;
  width: 100%;
  flex-direction: column;
  text-align: center;
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .modal-content .learn{
    width: 50%;
    text-align: start;
    align-items: flex-start;
  }
  
}

.modal-content .learn a{
  margin-top: 15px;
  background-color: transparent;
  border: #efefef solid 1px;
  padding: 5px 8px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: #161617;
  display: block;
  width: fit-content;
}
